/** @jsx jsx */
import * as React from 'react';
import { jsx, css, Global } from '@emotion/react';

import theme from '../theme';

const globalStyles = css`
    * {
        box-sizing: border-box;
    }
    body {
        ${theme.typography.body};
        margin: 0px;
        p {
            margin-bottom: 16px;
        }

        h1 {
          margin-block-start: 0;
        }
    }
    button {
      text-align: left;
      border-style: none;
      background-color: transparent;
      ${theme.typography.body};
    }
    ${theme.fontFaces};
`;

type Props = {
  children: React.ReactNode
};

function Layout(props: Props): React.ReactNode {
  const { children } = props;
  return (
    <React.Fragment>
      <Global styles={globalStyles} />
      {children}
    </React.Fragment>
  );
}

export default Layout;
