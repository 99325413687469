import { css } from '@emotion/react';

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 860,
  lg: 1280,
  xl: 1920,
};

type Breakpoint = keyof typeof breakpointValues;

const down = (key: Breakpoint): string => `@media(max-width: ${breakpointValues[key] - 1}px)`;
const up = (key: Breakpoint): string => `@media(min-width: ${breakpointValues[key]}px)`;

const breakpoints = {
  values: breakpointValues,
  down,
  up,
};

const palette = {
  primary: {
    main: '#be3904',
    light: '#D9B3A6',
  },
  common: {
    white: '#FFFFFF',
    black: '#000000',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
};

const parallax = {
  perspective: 2,
};

const fontFaces = css`
    @font-face {
        font-family: Niveau Grotesk;
        src: url("fonts/NiveauGrotesk-Bold.woff2") format("woff2"),
             url("fonts/NiveauGrotesk-Bold.woff") format("woff");
        font-display: swap;
        font-weight: bold;
      }
    @font-face {
        font-family: Niveau Grotesk;
        src: url("fonts/NiveauGrotesk-Black-SmallCaps.woff2") format("woff2"),
             url("fonts/NiveauGrotesk-Black-SmallCaps.woff") format("woff");
        font-display: swap;
        font-weight: 800;
        font-variant: small-caps;
      }
    @font-face {
        font-family: Niveau Grotesk;
        src: url("fonts/NiveauGrotesk-Light.woff2") format("woff2"),
             url("fonts/NiveauGrotesk-Light.woff") format("woff");
        font-display: swap;
      }
    @font-face {
        font-family: Lust Display;
        src: url("fonts/LustDisplay-Italic.woff2") format("woff2"),
             url("fonts/LustDisplay-Italic.woff") format("woff");
        font-display: italic;
      }
`;

const typography = {
  body: css`
    font-family: Niveau Grotesk, sans-serif;
    line-height: 1.5;
    a {
      color: ${palette.primary.main};
    }
  `,
  title: css`
    font-family: Lust Slim Display, serif;
    font-style: italic;
    color: ${palette.primary.main};
  `,
  subtitle: css`
    font-family: Niveau Grotesk, sans-serif;
    font-weight: 800;
    font-variant: small-caps;
    color: ${palette.primary.main};
  `,
  h1: css`
    font-family: Lust Display, serif;
    font-size: 3.2em;
    margin-bottom: 16px;
    text-transform: lowercase;
    text-align: center;
  `,
  h2: css`
    font-weight: bold;
    font-size: 1.6em;
    margin-bottom: 16px;
  `,
  label: css`
    font-size: 0.8em;
    margin: 0;
  `,
};

export type Typography = keyof typeof typography;

export default {
  breakpoints,
  fontFaces,
  palette,
  parallax,
  typography,
};
